<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Дорогие сотрудники компании «Фильтр», наши партнеры и клиенты!</h1>
        </div>
      </div>
      <hr>

      <h3>Мы рады приветствовать вас в нашем каталоге поощрений и вознаграждений</h3>
      <p class="text-danger">
        Обращаем внимание, с 10 апреля 2019 г. при заказе сертификатов по следующим брендам произойдет
        автоматическое удержание дополнительной комиссии за счет имеющихся бонусов сотрудника Компании:<br>
        – Летуаль + 5 %<br>
        – Озон + 6 %
      </p>
      <p>
        В нашем каталоге Вы можете выбрать электронный подарочный сертификат любого известного бренда.
        Получение сертификатов возможно в любое удобное время при положительном балансе свыше 500 руб.
        Выбранный сертификат будет мгновенно доставлен Вам на указанный e-mail, после подтверждения выбора.
        Вы можете выбирать любое количество сертификатов в день без ограничений.
      </p>

      <hr>

      <p>В случае возникновения вопросов Вы всегда можете обратиться в нашу службу поддержки удобным вам способом:</p>
      <p>
        Для клиентов и партнеров компании «Фильтр»:<br>
        <a href="mailto:support@giftery.ru">support@giftery.ru</a>,
        горячая линия: <a href="tel:88007009090">8-800-700-90-90</a><br>
        Для сотрудников компании «Фильтр», отдел персонала:<br>
        <a href="mailto:personal@stofilter.ru">personal@stofilter.ru</a>,
        тел. <a href="tel:89148957704">8-914-895-77-04</a>
      </p>
      <br>
      <router-link class="button button--large button--rounded" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
